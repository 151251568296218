
import './App.css';


import RouterPages from './Router/RouterPages';
import {BrowserRouter} from 'react-router-dom'





function App() {
  return (
    <BrowserRouter>
      <RouterPages/>
    </BrowserRouter>
  );
}

export default App;
